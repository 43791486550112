import React from "react"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { StaticImage } from "gatsby-plugin-image"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { DefaultHeader } from "components/headers/default-header"
// import { Pagination } from "components/pagination"
import { Footer } from "components/footer"
// import { useLocation } from "@reach/router"
// import Seo from "components/seo"
const blogs = require("data/blog.json")

const Ranking = props => {
  const title = "タイトル"
  // const route = useLocation()

  // const PerPage = 6
  // const pageCount = Math.ceil(blogs.length / PerPage)

  const rank2022 = blogs.filter(_ => _.ranking.length)

  return (
    <>
      <Helmet title={title} />
      <DefaultHeader />
      <main className="information">
        <section className="information__heading py-10 px-4 md:pt-14 md:pb-16">
          <div className="text-center">
            <h1 className="text-white font-bold">
              よく読まれているマーケティング記事
            </h1>
          </div>
        </section>
        <section className="md:py-16 pb-16 pt-4 l-container">
          <div>
            <ul className="md:flex md:flex-wrap blog-list__container">
              {rank2022.map((blog, index) => {
                const imageUrl = blog.imageUrl
                return (
                  <li key={index}>
                    <a className="blog-list__link" href={blog.href}>
                      <img
                        src={imageUrl}
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt={blog.alt}
                      />
                      <div className="mt-4 mb-2">
                        <span className="px-3 py-1 bg-primary text-white rounded text-xs inline-block">
                          {blog.type}
                        </span>
                      </div>
                      <p className="font-bold mb-3">{blog.title}</p>
                      <div className="blog-list__meta">
                        <p className="text-xs mb-1">{blog.date}</p>
                        <p className="text-xs font-bold">
                          本記事は{blog.minutes}で読むことができます
                        </p>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Ranking
